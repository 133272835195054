import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Left: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="22" height="22" viewBox="0 0 22 22" focusable="false" aria-hidden="true">
            <g fill={color} fillRule="nonzero">
                <path d="M3.01 15.296a.478.478 0 00.37-.159.51.51 0 000-.74C.264 11.283.264 6.265 3.38 3.202a.51.51 0 000-.74.51.51 0 00-.74-.001c-3.485 3.486-3.485 9.19 0 12.676a.572.572 0 00.37.159zM14.63 2.461a.512.512 0 000 .74c3.116 3.116 3.116 8.134 0 11.197a.512.512 0 000 .74.574.574 0 00.37.158.57.57 0 00.37-.159c3.486-3.486 3.486-9.19 0-12.729a.567.567 0 00-.74.053z" />
                <path d="M5.651 4.68a.509.509 0 00-.739 0 5.833 5.833 0 000 8.239.569.569 0 00.37.158.57.57 0 00.37-.158.513.513 0 000-.74 4.746 4.746 0 010-6.707.577.577 0 000-.792zm7.448 0a.51.51 0 00-.74 0 .51.51 0 000 .739 4.75 4.75 0 010 6.708.512.512 0 000 .74.574.574 0 00.37.158.57.57 0 00.37-.159c2.27-2.218 2.27-5.915 0-8.186zM11.673 8.8a2.616 2.616 0 00-2.641-2.642 2.615 2.615 0 00-2.641 2.641 2.614 2.614 0 002.64 2.641 2.615 2.615 0 002.642-2.64zm-4.226 0c0-.899.687-1.585 1.585-1.585.898 0 1.584.686 1.584 1.584s-.686 1.585-1.584 1.585-1.585-.687-1.585-1.585z" />
            </g>
        </svg>
    );
};

export default Left;
